$(document).ready(function() {
  const anchor = window.location.hash;

  $('.toast').toast('show');
  $('[data-bs-toggle="tooltip"]').tooltip();
  if (document.getElementById('js-snippet')) {
    hljs.registerLanguage('javascript', jsLang);
    hljs.highlightAll();
  }

  if (document.getElementById('json-snippet')) {
    hljs.registerLanguage('json', jsonLang);
    hljs.highlightAll();
  }

  $(document).on('click', '.copy_code', function () {
    var copyText = $(this).prev('code');
    var input = document.createElement('textarea');
    input.value = copyText.text().replace(/\n/g, "\r\n");
    document.body.appendChild(input);
    input.select();
    input.setSelectionRange(0, 99999); // For mobile devices
    navigator.clipboard.writeText(input.value);
    document.execCommand('copy');
    document.body.removeChild(input);

    $(this).children('span').text('  Copied!');
  });

  $(document).on('click', '.tooltip-copy', function () {
    var copyText = $(this).data('copy-text');
    var input = document.createElement('textarea');
    input.value = copyText;
    document.body.appendChild(input);
    input.select();
    input.setSelectionRange(0, 99999); // For mobile devices
    navigator.clipboard.writeText(input.value);
    document.execCommand('copy');
    document.body.removeChild(input);

    $('#copy-toast').addClass('toast').removeClass('d-none').toast('show');
  });

  $('input[name="user[bot_list][]"').on('change', function() {
    if ($(this).is(':checked')) {
      if ($(this).val() === '0') {
        $('input[name="user[bot_list][]"').not(this).not(':hidden, :disabled').prop('checked', false);
      } else {
        $('#user_bot_list_0').prop('checked', false);
      }
    }
  });

  $('.bucket_update_link').on('click', function() {
    var parent = $(this).parents('.bucket_show_text');
    parent.hide();
    parent.next('.bucket_update_form').removeClass('d-none');
  });

  $('.bucket_update_cancel').on('click', function() {
    var parent = $(this).parents('.bucket_update_form');
    parent.addClass('d-none');
    parent.prev('.bucket_show_text').show();
  });

  if (anchor) {
    const tabElement = document.querySelector(`[data-bs-toggle="tab"][data-bs-target="${anchor}"]`);
    if (tabElement) { tabElement.click(); }
  }
});
